import Image from 'next/image'
import {type RefObject} from 'react'

type Props = {
  videoUrl: string
  videoFormat?: string
  fallbackImageUrl: string
  className?: string
  preload?: 'auto' | 'metadata' | 'none'
  playsInline?: boolean
  autoPlay?: boolean
  loop?: boolean
  muted?: boolean
  controls?: boolean
  cover?: boolean
  ref?: RefObject<HTMLVideoElement>
}
const Video = ({
  videoUrl,
  videoFormat = 'video/mp4',
  fallbackImageUrl,
  className = '',
  preload = 'metadata',
  playsInline = true,
  autoPlay = true,
  loop = true,
  muted = true,
  controls = false,
  cover = true,
  ref = undefined
}: Props) => (
  <video
    ref={ref}
    playsInline={playsInline}
    autoPlay={autoPlay}
    loop={loop}
    muted={muted}
    controls={controls}
    preload={preload}
    className={`${className} aspect-video w-full ${cover ? 'object-cover' : ''}`}
    poster={fallbackImageUrl}
  >
    <source src={videoUrl} type={videoFormat} />
    <Image src={fallbackImageUrl} alt="Fallback image. Video did not load." fill blurDataURL={fallbackImageUrl} placeholder="blur" />
  </video>
)

export default Video
