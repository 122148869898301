import {SVGProps} from 'react'

const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.85002 15.7385L15.7385 12L9.85002 8.26149V15.7385ZM12.0085 21.1C10.7567 21.1 9.57769 20.8632 8.47152 20.3895C7.36536 19.9158 6.39719 19.2641 5.56702 18.4342C4.73686 17.6044 4.08477 16.637 3.61077 15.532C3.13694 14.4272 2.90002 13.2466 2.90002 11.9902C2.90002 10.7339 3.13686 9.55683 3.61052 8.45899C4.08419 7.36116 4.73594 6.39716 5.56577 5.56699C6.39561 4.73683 7.36302 4.08474 8.46802 3.61074C9.57286 3.13691 10.7534 2.89999 12.0098 2.89999C13.2661 2.89999 14.4432 3.13683 15.541 3.61049C16.6389 4.08416 17.6029 4.73591 18.433 5.56574C19.2632 6.39558 19.9153 7.36099 20.3893 8.46199C20.8631 9.56316 21.1 10.7397 21.1 11.9915C21.1 13.2433 20.8632 14.4223 20.3895 15.5285C19.9159 16.6347 19.2641 17.6028 18.4343 18.433C17.6044 19.2632 16.639 19.9152 15.538 20.3892C14.4369 20.8631 13.2604 21.1 12.0085 21.1ZM12 19.8C14.1667 19.8 16.0084 19.0417 17.525 17.525C19.0417 16.0083 19.8 14.1667 19.8 12C19.8 9.83333 19.0417 7.99166 17.525 6.47499C16.0084 4.95833 14.1667 4.19999 12 4.19999C9.83336 4.19999 7.99169 4.95833 6.47502 6.47499C4.95836 7.99166 4.20002 9.83333 4.20002 12C4.20002 14.1667 4.95836 16.0083 6.47502 17.525C7.99169 19.0417 9.83336 19.8 12 19.8Z"
      fill="currentColor"
    />
  </svg>
)

export default PlayIcon
