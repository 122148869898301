'use client'
import {useIsVisible} from '@/hooks/useIsVisible'
import {type Component, useRef} from 'react'

type PolymorphicProps<T extends React.ElementType> = React.PropsWithChildren<
  React.ComponentPropsWithoutRef<T> & {
    as?: T
  }
>
type Props<T extends React.ElementType> = {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  threshold?: number
  as?: Component<T> // TS is not accepting as parameter while in use.
} & PolymorphicProps<T>

const FadeUpOnVisible = <T extends React.ElementType = 'div'>({className = '', style, as, children, threshold, ...props}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(ref, {threshold: threshold ?? 0.3})

  const Component = (as || 'div') as React.ElementType

  return (
    <Component
      ref={ref}
      className={`transition duration-500 ease-in ${isVisible ? 'opacity-1 translate-0' : 'translate-y-3 opacity-0'} ${className}`}
      style={style}
      {...props}
    >
      {children}
    </Component>
  )
}

export default FadeUpOnVisible
