'use client'
import {useIsVisible} from '@/hooks/useIsVisible'
import {useEffect, useRef, useState} from 'react'

const ValuesParallax = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState(0)
  const isVisible = useIsVisible(ref, {hideOffScroll: true})

  useEffect(() => {
    const handleScroll = () => {
      if (!isVisible) return window.removeEventListener('scroll', handleScroll)
      setPosition(50 - window.scrollY / 15)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [isVisible])

  const textClasses = 'font-azeret text-[80px] font-medium leading-[80px] text-transparent lg:text-[188px] lg:leading-[188px]'
  const backgroundClasses =
    "bg-[url('/images/ledventure-1.webp')] bg-clip-text bg-[length:361px_246px] bg-[top_-34px_left_-70px] md:bg-[length:534px_364px] md:bg-[top_-75px_left_-105px] lg:bg-[length:718px_274px] lg:bg-[top_0px_left_-10px]"

  return (
    <div ref={ref} className={`${textClasses} ${backgroundClasses} text-center`} style={{backgroundPositionY: `${position}px`}}>
      300
      <br className="md:hidden" />
      MSEK+
    </div>
  )
}

export default ValuesParallax
