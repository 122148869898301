'use client'
import Video from '@/components/Video'
import {useIsVisible} from '@/hooks/useIsVisible'
import PauseIcon from '@/icons/PauseIcon'
import PlayIcon from '@/icons/PlayIcon'
import {useEffect, useRef, useState} from 'react'

const ServicesVideo = () => {
  const ref = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState(0)
  const [play, setPlay] = useState(true)
  const isVisible = useIsVisible(ref, {hideOffScroll: true})

  useEffect(() => {
    if (window.innerWidth < 1439) {
      setWidth(ref.current?.parentElement?.clientWidth ?? 0)
      return
    }

    const maxWidth = ref.current?.parentElement?.clientWidth ?? 0
    const minWidth = maxWidth * 0.6
    const screenCenter = window.innerHeight / 2

    const handleScroll = () => {
      if (!isVisible) return window.removeEventListener('scroll', handleScroll)

      if (width === maxWidth && open) return

      const videoCenter = ((ref.current?.getBoundingClientRect().top ?? 0) + (ref.current?.getBoundingClientRect().bottom ?? 0)) / 2
      const distanceToCenter = Math.max(videoCenter - screenCenter, 0)
      const totalDistance = screenCenter
      const progress = Math.min((distanceToCenter - totalDistance) / totalDistance, 1)
      const newWidth = minWidth + -progress * (maxWidth - minWidth)

      setWidth(newWidth)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [isVisible, open, width])

  const onOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })

    setOpen(true)
  }

  const onPausePlay = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (play) videoRef.current?.pause()
    else videoRef.current?.play()

    setPlay(prev => !prev)
  }

  return (
    <>
      <div className="relative flex aspect-video justify-center grid-sublayout-default">
        <div className="relative aspect-video cursor-pointer" style={{width}} ref={ref} onClick={onOpen}>
          <div className="relative">
            <Video
              ref={videoRef}
              videoUrl="/videos/Led-Hero-video-Short.mp4"
              fallbackImageUrl="/videos/ledventure-video-fallback.webp"
              preload="auto"
            />
            <div className="absolute bottom-4 right-4 text-white" onClick={onPausePlay}>
              {play ? <PauseIcon /> : <PlayIcon />}
            </div>
          </div>
        </div>
        {open && (
          <div className="absolute top-0 z-50 my-auto transition-[width] duration-500 ease-out grid-layout-default" style={{width}}>
            <Video
              videoUrl="/videos/Led-Hero-video-Long.mp4"
              fallbackImageUrl="/videos/ledventure-video-fallback.webp"
              preload="none"
              autoPlay={true}
              loop={false}
              muted={false}
              controls={true}
              cover={false}
            />
          </div>
        )}
      </div>
      {open && (
        <div className="fixed left-0 top-0 z-40 h-screen w-screen animate-fade-in-90 bg-black opacity-90" onClick={() => setOpen(false)} />
      )}
    </>
  )
}

export default ServicesVideo
