import(/* webpackMode: "eager" */ "/opt/build/repo/animations/FadeUpOnVisible.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/(max-width)/(Home)/components/ServicesVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/(max-width)/(Home)/components/ValuesParallax.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/images/ledventure-2.webp");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/public/images/ledventure-3.webp");
