import {type RefObject, useEffect, useState} from 'react'

type Options = {
  hideOffScroll?: boolean
  threshold?: number
}
export function useIsVisible<T extends Element>(ref: RefObject<T>, options?: Options) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const {hideOffScroll, threshold} = options || {}

    const observer = new IntersectionObserver(([entry]) => setIntersecting((!hideOffScroll && isIntersecting) || entry.isIntersecting), {
      threshold: threshold || 0.1
    })

    observer.observe(ref.current!)

    return () => observer.disconnect()
  }, [isIntersecting, options, ref])

  return isIntersecting
}
